import React, { useMemo, useCallback, useEffect } from 'react';

const SurfOverview = ({ surfInfoData, windData, onHoverIndex, hoverIndex, isServer, closestTimeIndex }) => {
  // Early return for loading state
  if (!surfInfoData || surfInfoData.length === 0 || !windData) {
    return <div>Loading Wave Energy and Wind Gusts Data...</div>;
  }

  // Helper function to format dates
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
  };

  // Memoize grouped energies by date
  const groupedEnergies = useMemo(() => {
    return surfInfoData.reduce((acc, item) => {
      const date = formatDate(item.date);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  }, [surfInfoData]);

  // Get unique days from grouped energies
  const uniqueDays = useMemo(() => Object.keys(groupedEnergies), [groupedEnergies]);

  // Set initial hover index to closest time index
  useEffect(() => {
    if (!isServer && closestTimeIndex !== undefined) {
      onHoverIndex(closestTimeIndex);
    }
  }, [isServer, closestTimeIndex, onHoverIndex]);

  // Define handlers (only for client-side)
  const handleMouseEnter = useCallback((index) => {
    if (!isServer) {
      window.requestAnimationFrame(() => onHoverIndex(index));
    }
  }, [onHoverIndex, isServer]);

  const handleTouchStart = useCallback((index) => {
    if (!isServer) {
      onHoverIndex(index);
    }
  }, [onHoverIndex, isServer]);

  const handleTouchMove = useCallback((event) => {
    if (!isServer) {
      const touch = event.touches[0];
      const container = event.currentTarget;

      if (container) {
        const rect = container.getBoundingClientRect();
        const touchX = touch.clientX - rect.left;
        const index = Math.floor((touchX / rect.width) * surfInfoData.length);

        if (index >= 0 && index < surfInfoData.length) {
          onHoverIndex(index);
        }
      }
    }
  }, [onHoverIndex, surfInfoData.length, isServer]);

  const handleTouchEnd = useCallback(() => {
    // Do nothing when touch ends to keep the last hovered index
  }, []);

  return (
    <div className="mt-1 rounded-sm bg-zinc-100 dark:bg-zinc-800">
      {/* Days header */}
      <div className="flex px-1 py">
        {uniqueDays.map((day, index) => {
          const dayData = groupedEnergies[day];
          const totalBars = dayData.length;
          const flexBasis = `${(totalBars / surfInfoData.length) * 100}%`;

          return (
            <div key={index} style={{ flexBasis }} className="flex justify-center relative">
              <span className="text-xs font-semibold">{day}</span>
            </div>
          );
        })}
      </div>

      {/* Energy and Wind Gusts bars */}
      <div className="flex overflow-x-auto relative touch-none"
           onTouchMove={isServer ? undefined : handleTouchMove} 
           onTouchEnd={isServer ? undefined : handleTouchEnd}>
        {surfInfoData.map((item, index) => (
          <React.Fragment key={index}>
            <div
              className="relative flex-grow"
              onMouseEnter={isServer ? undefined : () => handleMouseEnter(index)}
              onTouchStart={isServer ? undefined : () => handleTouchStart(index)}
            >
              <div
                className={`absolute inset-0 ${
                  index === closestTimeIndex
                    ? 'bg-pink-400/[.55] z-10'
                    : index === hoverIndex
                    ? 'bg-red-400/[.65] z-30'
                    : 'z-0'
                }`}
                style={{ height: '7rem' }}
              />
              <div className="relative w-full" style={{ height: '7rem' }}>
                {/* Energy bar */}
                <div
                  className="absolute bottom-0 left-0 w-full bg-yellow-500 dark:bg-yellow-600"
                  style={{ height: `${(Math.min(item.energy, 2000) / 2000) * 100}%`, borderRadius: 0 }}
                />
                {/* Wind Gusts bar */}
                <div
                  className="absolute bottom-0 left-0 w-full bg-blue-500/[.4] dark:bg-blue-600/[0.58]"
                  style={{ height: `${(Math.min(windData.hourly.wind_gusts_10m[index], 70) / 70) * 100}%`, borderRadius: 0 }}
                />
              </div>
            </div>

            {/* Add separator line between days */}
            {new Date(item.date).getHours() === 23 && index < surfInfoData.length - 1 && (
              <div
                className="absolute w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.8] h-full z-40"
                style={{ left: `${((index + 1) / surfInfoData.length) * 100}%`, zIndex: 10 }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SurfOverview;