import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { PiWavesBold, PiAngleBold } from 'react-icons/pi';
import { SlEnergy } from 'react-icons/sl';
import { TbLineHeight, TbArrowAutofitWidth, TbWaveSine } from 'react-icons/tb';
import { FaWind } from 'react-icons/fa';
import { RiInformation2Fill } from 'react-icons/ri';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const COLOR = {
  waveEnergy: 'bg-yellow-500 dark:bg-yellow-600',
  waveHeight: 'bg-amber-500 dark:bg-amber-600',
  swellHeight: 'bg-orange-500/[.85] dark:bg-orange-600/[.85]',
  windChop: 'bg-sky-400/[.85] dark:bg-sky-600',
  barBackground: 'bg-zinc-200 dark:bg-zinc-700',
  tideHeight: 'bg-violet-400 dark:bg-violet-700',
  arrowColor: 'inherit',
  windGusts: 'bg-blue-400 dark:bg-blue-600',
};

const SurfInfoTable = ({ 
  data, 
  tideData = [], 
  labels, 
  waveEnergies, 
  groupDates, 
  isLastHourOfDay, 
  hoverIndex, 
  onCellClick, 
  showAllTooltips, 
  setShowAllTooltips,
  closestTimeIndex,
  windData // Add windData prop
}) => {

  const [showHiddenRows, setShowHiddenRows] = useState(false);

  const handleClick = (index) => {
    if (onCellClick) {
      onCellClick(index);
    }
  };

  const renderCell = (value, index, color, maxValue, minValue = 0, unit = '') => {
    const range = maxValue - minValue;
    const normalizedValue = (value - minValue) / range;
    const height = `${Math.max(0, Math.min(normalizedValue, 1)) * 100}%`;
    
    const formattedValue = value === 0 ? '' : value ? Number(value.toFixed(2)).toString() : '-';
  
    return (
      <td
        className={`p-px min-w-9 border-b border-zinc-300 dark:border-zinc-600 ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''} ${index === closestTimeIndex ? 'bg-pink-200/[.7] dark:bg-pink-400/[.2]' : ''}`}
        onClick={() => handleClick(index)}
      >
        <div className="relative h-12 sm:h-14 lg:h-16 ">
          <div
            className={`absolute bottom-0 w-full ${color} text-white text-center rounded-sm`}
            style={{ height }}
          />
          <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-zinc-800 dark:text-zinc-100">
            {formattedValue}{unit}
          </span>
        </div>
      </td>
    );
  };

  const renderArrowCell = (value, index) => (
    <td 
      className={`py-0 sm:py-1 border-b border-zinc-300 dark:border-zinc-600 text-center ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''} ${index === closestTimeIndex ? 'bg-pink-200/[.7] dark:bg-pink-400/[.2]' : ''}`}
      onClick={() => handleClick(index)}
      style={{ verticalAlign: 'middle' }} 
    >
      <span
        className="inline-block"
        style={{ 
          transform: `rotate(${value}deg)`, 
          color: COLOR.arrowColor,
          lineHeight: 1,
          verticalAlign: 'center'
        }}
      >
        &#8595;
      </span>
    </td>
  );

  return (
    <div className="shadow-sm rounded-sm">
      <div className="overflow-x-auto rounded-sm will-change-transform">
        <table className="bg-zinc-100 dark:bg-zinc-700 border border-zinc-300 dark:border-zinc-600 rounded-lg">
          <thead>
            <tr className="bg-zinc-100 dark:bg-zinc-700 text-zinc-800 dark:text-zinc-100">
              <th className="text-sm text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-b-3 border-zinc-300 dark:border-zinc-600" onClick={() => setShowAllTooltips(!showAllTooltips)} onMouseEnter={() => setShowAllTooltips(true)}
    onMouseLeave={() => setShowAllTooltips(false)} style={{ left: '-1px' }}>
                <div className=" text-lg flex justify-center items-center">
                  <RiInformation2Fill />
                </div>
              </th>
              {Object.entries(groupDates(labels)).map(([date, { startIndex, endIndex }]) => (
                <th 
                  key={date}
                  colSpan={endIndex - startIndex + 2}
                  className="p-1.5 border-b border-zinc-300 dark:border-zinc-600"
                >
                  {date}
                </th>
              ))}
            </tr>
            <tr className="bg-zinc-100 dark:bg-zinc-700 text-zinc-800 dark:text-zinc-100"> 
              <th className="sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600" style={{ left: '-1px' }}> 
              <a className="appendSwell" >
    <button 
          className="mt-1 bg-zinc-100 dark:bg-zinc-700 py-1 px-2 rounded"
          onClick={() => setShowHiddenRows(!showHiddenRows)}
        >
          {showHiddenRows ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
       
        </a>
        </th> 
              {labels.map((label, index) => (
                <React.Fragment key={index}>
                  <th 
                    className={`p-1.5 border-b border-zinc-300 dark:border-zinc-600 ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''} ${index === closestTimeIndex ? 'bg-pink-200/[.7] dark:bg-pink-400/[.2]' : ''}`}
                    onClick={() => handleClick(index)}
                  >
                    {label.split(' ')[1]}
                  </th>
                  {isLastHourOfDay(data.hourly.time[index]) && index < labels.length - 1 && (
                    <th className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]">
                      
                    </th>
                  )}
                </React.Fragment>
              ))}
              
            </tr>
            <Tooltip anchorSelect=".appendSwell" place="right" isOpen={showAllTooltips}>
                Append Wave Info - Groundswell & Windswell
                </Tooltip>
               
          </thead>
       
          <tbody>
            {/* Wave Energy row */}
            <tr>
              <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600" style={{ left: '-1px' }}>
                <a className="flex justify-center waveE" >
                  <PiWavesBold /><SlEnergy />
                </a >
                <Tooltip anchorSelect=".waveE" place="right" isOpen={showAllTooltips}>
                  Wave Energy (kJ:m²)
                </Tooltip>
                <Tooltip anchorSelect=".waveE" place="right">
                  Wave Energy (kJ:m²)
                </Tooltip>
             
              </td>             
              {waveEnergies.map((energy, index) => (
                <React.Fragment key={index}>
                  {renderCell(energy, index, COLOR.waveEnergy, 2000)}
                  {isLastHourOfDay(data.hourly.time[index]) && index < waveEnergies.length - 1 && (
                    <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                  )}
                </React.Fragment>
              ))}
            </tr>
{/* Wave Height row */}
<tr>
  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
    <a className="flex justify-center items-center waveHeight">
      <PiWavesBold /><TbLineHeight />
    </a>
    <Tooltip anchorSelect=".waveHeight" place="right" isOpen={showAllTooltips}>
      Wave Height (meters)
    </Tooltip>
    <Tooltip anchorSelect=".waveHeight" place="right">
      Wave Height (meters)
    </Tooltip>
  </td>
  {data.hourly.wave_height.map((value, index) => (
    <React.Fragment key={index}>
      {renderCell(value, index, COLOR.waveHeight, 3)}
      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.wave_height.length - 1 && (
        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
      )}
    </React.Fragment>
  ))}
</tr>

{/* Wave Period row */}
            <tr>
  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>  
    <a className="flex justify-center items-center wavePeriod">
      <PiWavesBold /><TbArrowAutofitWidth />
    </a>
    <Tooltip anchorSelect=".wavePeriod" place="right" isOpen={showAllTooltips}>
      Wave Period (seconds)
    </Tooltip>
    <Tooltip anchorSelect=".wavePeriod" place="right">
      Wave Period (seconds)
    </Tooltip>
  </td>
  {data.hourly.wave_period.map((value, index) => (
    <React.Fragment key={index}>
      <td 
        className={`border-b border-zinc-300 dark:border-zinc-600 text-xs text-center font-semibold ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''} ${index === closestTimeIndex ? 'bg-pink-200/[.7] dark:bg-pink-400/[.2]' : ''}`}
        onClick={() => handleClick(index)}
      >
        {value}
      </td>
      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.wave_period.length - 1 && (
        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
      )}
    </React.Fragment>
  ))}
</tr>
          
          {/* Wave Direction row */}
<tr>
  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
    <a className="flex justify-center items-center waveDir">
      <PiWavesBold /><PiAngleBold />
    </a>
    <Tooltip anchorSelect=".waveDir" place="right" isOpen={showAllTooltips}>
      Wave Direction
    </Tooltip>
    <Tooltip anchorSelect=".waveDir" place="right">
      Wave Direction
    </Tooltip>

  </td>
  {data.hourly.wave_direction.map((value, index) => (
    <React.Fragment key={index}>
      {renderArrowCell(value, index)}
      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.wave_direction.length - 1 && (
        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
      )}
    </React.Fragment>
  ))}
</tr>

            {showHiddenRows && (
              <>
                {/* Swell Height row */}
                <tr>
                  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
                    <a className="flex justify-center items-center waveH">
                      <PiWavesBold /><TbLineHeight />
                    </a>
                    <Tooltip anchorSelect=".waveH" place="right" isOpen={showAllTooltips}>
                      Swell Height (meters)
                    </Tooltip>
                    <Tooltip anchorSelect=".waveH" place="right">
                      Swell Height (meters)
                    </Tooltip>
                  </td>
                  {data.hourly.swell_wave_height.map((value, index) => (
                    <React.Fragment key={index}>
                      {renderCell(value, index, COLOR.swellHeight, 3)}
                      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.swell_wave_height.length - 1 && (
                        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
                {/* Swell Period row */}
                <tr>
                  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>  
                    <a className="flex justify-center items-center waveS">
                      <PiWavesBold /><TbArrowAutofitWidth />
                    </a>
                    <Tooltip anchorSelect=".waveS" place="right" isOpen={showAllTooltips}>
                      Swell Period (seconds)
                    </Tooltip>
                    <Tooltip anchorSelect=".waveS" place="right">
                      Swell Period (seconds)
                    </Tooltip>
                  </td>
                  {data.hourly.swell_wave_period.map((value, index) => (
                    <React.Fragment key={index}>
                      <td 
                        className={`border-b border-zinc-300 dark:border-zinc-600 text-xs text-center font-semibold ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''} ${index === closestTimeIndex ? 'bg-pink-200/[.7] dark:bg-pink-400/[.2]' : ''}`}
                        onClick={() => handleClick(index)}
                      >
                        {value}
                      </td>
                      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.swell_wave_period.length - 1 && (
                        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
                {/* Swell Angle row */}
                <tr>
                  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
                    <a className="flex justify-center items-center  waveD">
                      <PiWavesBold /><PiAngleBold />
                    </a>
                    <Tooltip anchorSelect=".waveD" place="right" isOpen={showAllTooltips}>
                      Swell Angle / Direction
                    </Tooltip>
                    <Tooltip anchorSelect=".waveD" place="right">
                      Swell Angle / Direction
                    </Tooltip>
                  </td>
                  {data.hourly.swell_wave_direction.map((value, index) => (
                    <React.Fragment key={index}>
                      {renderArrowCell(value, index)}
                      {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.swell_wave_direction.length - 1 && (
                        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
                {/* Wind Chop row */}
                <tr>
                  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
                    <a className="flex justify-center items-center  windH">
                      <FaWind className="text-sm"/><TbLineHeight />
                    </a>
                    <Tooltip anchorSelect=".windH" place="right" isOpen={showAllTooltips}>
                      Wind Chop / Wind Swell (meters)
                    </Tooltip>
                    <Tooltip anchorSelect=".windH" place="right">
                      Wind Chop / Wind Swell  (meters)
                    </Tooltip>
                  </td>
                  {data.hourly.wind_wave_height.map((value, index) => (
                <React.Fragment key={index}>
                  {renderCell(value, index, COLOR.windChop, 3)}
                  {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.wind_wave_height.length - 1 && (
                    <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                  )}
                </React.Fragment>
              ))}
            </tr>
            {/* Chop Angle row */}
            <tr>
              <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-b border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
                <a className="flex justify-center items-center  windD">
                  <FaWind className="text-sm"/><PiAngleBold />
                </a>
                <Tooltip anchorSelect=".windD" place="right" isOpen={showAllTooltips}>
                  Wind Chop Angle / Direction
                </Tooltip>
                <Tooltip anchorSelect=".windD" place="right">
                  Wind Chop Angle / Direction
                </Tooltip>
              </td>
              {data.hourly.wind_wave_height.map((value, index) => (
                <React.Fragment key={index}>
                  {value !== 0 ? renderArrowCell(data.hourly.wind_wave_direction[index], index) :  <td  
                    className={`text-center text-justify border-b border-zinc-300 dark:border-zinc-600 ${index === hoverIndex ? 'bg-red-200/[.6] dark:bg-red-400/[.2]' : ''}`}
                    onClick={() => handleClick(index)}
                  ></td>}
                  {isLastHourOfDay(data.hourly.time[index]) && index < data.hourly.wind_wave_height.length - 1 && (
                    <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
                  )}
                </React.Fragment>
              ))}
            </tr>
          </>
        )}

{/* Wind Gusts row */}
<tr>
  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
    <a className="flex justify-center items-center windGusts">
      <FaWind className="text-sm" /><SlEnergy />
    </a>
    <Tooltip anchorSelect=".windGusts" place="right" isOpen={showAllTooltips}>
      Wind Gusts (m/s)
    </Tooltip>
    <Tooltip anchorSelect=".windGusts" place="right">
      Wind Gusts (m/s)
    </Tooltip>
  </td>
  {windData.hourly.wind_gusts_10m.map((value, index) => (
    <React.Fragment key={index}>
      {renderCell(value, index, COLOR.windGusts, 70)}
      {isLastHourOfDay(data.hourly.time[index]) && index < windData.hourly.wind_gusts_10m.length - 1 && (
        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
      )}
    </React.Fragment>
  ))}
</tr>

{/* Wind Direction row */}
<tr>
  <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
    <a className="flex justify-center items-center windDir">
      <FaWind className="text-sm" /><PiAngleBold />
    </a>
    <Tooltip anchorSelect=".windDir" place="right" isOpen={showAllTooltips}>
      Wind Direction
    </Tooltip>
    <Tooltip anchorSelect=".windDir" place="right">
      Wind Direction
    </Tooltip>
  </td>
  {windData.hourly.wind_direction_10m.map((value, index) => (
    <React.Fragment key={index}>
      {renderArrowCell(value, index)}
      {isLastHourOfDay(data.hourly.time[index]) && index < windData.hourly.wind_direction_10m.length - 1 && (
        <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
      )}
    </React.Fragment>
  ))}
</tr>


        {/* Tide Height row */}
        <tr>
          <td className="text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600 p-1.5 font-medium" style={{ left: '-1px' }}>
            <a className="flex justify-center items-center tideH">
              <TbWaveSine /><TbLineHeight />
            </a>
            <Tooltip anchorSelect=".tideH" place="right" isOpen={showAllTooltips}>
              Tide Height (meters)
            </Tooltip>
            <Tooltip anchorSelect=".tideH" place="right">
              Tide Height (meters)
            </Tooltip>
          </td>
          {Array.isArray(tideData) && tideData.map((value, index) => (
            <React.Fragment key={index}>
              {renderCell(value.height, index, COLOR.tideHeight, 3, -3)}
              {isLastHourOfDay(data.hourly.time[index]) && index < tideData.length - 1 && (
                <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
              )}
            </React.Fragment>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
</div>
);
};

export default SurfInfoTable;